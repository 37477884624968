<template>
  <div class="iss-main timeline">
    <div class="timeline-header">
      <div class="title-group">
        <span>时刻</span>
        <span>记录相处的点点滴滴，抓住时间的尾巴！</span>
      </div>
      <a-button type="primary" @click="openModal">新增记录</a-button>
    </div>
    <TimeLine ref="timeline"></TimeLine>
<!--   联系人&人脉圈 记录   -->
    <add-recording
      :visible="addShow"
      @closeModal="addModalClose"
      :init="activeItem"
      @fnOk="handleAddRecording"
    />
  </div>
</template>

<script>
export default {
  name: 'timeline',
};
</script>
<script setup>
import { reactive, ref } from 'vue';
import TimeLine from '@/views/timeline/components/timeline.vue';
// import AddModal from '@/views/timeline/components/addModal.vue';
import AddRecording from '@/views/timeline/components/addRecording';

const addShow = ref(false);
const timeline = ref();
const activeItem = reactive({});

const addModalClose = e => {
  addShow.value = false;
  if (e) {
    // infoData()
    console.log('数据刷新');
    timeline.value.initData();
  }
};


const openModal = () => {
  let parmas = {
    comeFrom: 'timelineOne',
    type: 'add',
  };

  addShow.value = true;
  Object.assign(activeItem, parmas);
};
const handleAddRecording = (value) =>{
  addShow.value = value;
  if (!value) {
    timeline.value.initData();
  }

};

</script>

<style lang="less" scoped>
.timeline {
  width: 100%;
  padding: 40px 72px;
  box-sizing: border-box;

  .timeline-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    .title-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      span:nth-child(1) {
        color: #333333;
        font-size: 24px;
        font-weight: bold;
        white-space: nowrap;
      }

      span:nth-child(2) {
        color: #666666;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
      }
    }
  }
}
</style>
·
